import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import Container from '../styles/Container'

import swoopLight from '../images/swoop-light.png'
import cursorWhite from '../images/cursor-white.svg'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #0859FF;
    background-image: none;
    // cursor: url(${cursorWhite}) 7 7, auto;
  }
  a, button, label, input, select, textarea {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
`

const Wrapper = styled.div`
  position: relative;
  margin-top: 90px;
  margin-bottom: 70px;
`

const Bottom = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    top: 155px;
    left: 443px;
  }
`

const Heading = styled.h1`
  max-width: 187px;
  padding-left: 20px;
  margin-bottom: 37px;
  font-family: Dignitas, sans-serif;
  font-size: 44px;
  line-height: 47px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #faf6f3;
  @media (min-width: 1200px) {
    max-width: 600px;
    padding-left: 116px;
    margin-bottom: 340px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
  }
`

const Paragraph = styled.p`
  max-width: 242px;
  padding-left: 55px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #efe0d7;
  a {
    font-weight: 700;
  }
  @media (min-width: 1200px) {
    max-width: 336px;
    padding-left: 0px;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 22px;
    transform: translate;
  }
`

const SwoopContainer = styled.div`
  margin-top: 21px;
  padding-left: 85px;
  @media (min-width: 1200px) {
    margin-top: 38px;
    padding-left: 0px;
    margin-left: -132px;
  }
`

const SwoopImg = styled.img`
  width: 99px;
  @media (min-width: 1200px) {
    width: 197px;
  }
`

const PageNotFound = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Wrapper>
          <Heading>page not found</Heading>
          <Bottom>
            <Paragraph>
              It looks like the page you&apos;re looking for might have been removed, had its name changed, or is
              temporarily unavailable.
            </Paragraph>
            <Paragraph>
              Head back to our homepage by <Link to="/">clicking here</Link>
            </Paragraph>
            <SwoopContainer>
              <SwoopImg src={swoopLight} alt="" />
            </SwoopContainer>
          </Bottom>
        </Wrapper>
      </Container>
    </>
  )
}

export default PageNotFound
